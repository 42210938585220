const MODULE = {
  PROJECTS: {
    ACTION_ID: 23,
    MODULE_ID: 2,
    UX: {
      SCHEDULES: 97,
      TYPE: 44,
      STATUS: 43,
      SERVICE_MATERIAL: 25,
    }
  },
  TASK: 30,
  CLIENT: {
    ACTION_ID: 6,
    UX: {
      LOCATION: 16
    }
  },
  TECHNICIAN_STATUS: 54,
};

export default MODULE;
