<template>
  <div class="row">
    <div class="col-md-12 d-flex mb-3 align-items-center  ">
      <div class="w-100 me-3">
        <input
          class="form-control"
          type="text"
          v-model="title"
          placeholder="Summary/Title/Subject (Optional)"
        />
      </div>
      <div class="">
        <bryntum-button
          icon="b-fa-file"
          text="Add Note"
          color="b-purple"
          cls="b-raised align-items-center"
          @click="addNotes"
        />
    </div>
    </div>
    <div class="col-md-12 mb-3">
      <textarea
        v-model="description"
        class="form-control"
        cols="30"
        rows="4"
      ></textarea>
    </div>

    <div class="col-md-12">
      <div class="card" v-for="note in notes" :key="note.id">
        {{ note }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { GlobalService } from "@/services/GlobalService";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
export default {
  props: {
    projectId: [String, Number],
  },
  components: {
    BryntumButton,
  },
  setup(props) {
    const globalService = new GlobalService();
    const route = useRoute();
    const timer = ref(null);
    const notes = ref([]);
    const title = ref("");
    const description = ref("");
    const params = reactive({
      t: route.query.t,
      u: route.query.u,
      uid: 1,
      userID: 1,
      s: "Jupiter",
      projectId: props.projectId,
      typeid: 3,
    });

    const loadNotes = async () => {
      notes.value = await globalService.getProjectNotes(params);
    };

    const setupInterval = () => {
      if (timer.value) return;
      timer.value = setInterval(loadNotes, 5000);
    };

    const addNotes = async () => {
      if (!description.value) return;
      await globalService.addProjectNote({
        ...params,
        notes: title.value,
        summary: description.value,
        id: 0,
      });

      title.value = "";
      description.value = "";
    };

    onMounted(() => {
      loadNotes();
      setupInterval();
    });

    onUnmounted(() => {
      clearInterval(timer.value);
      timer.value = null;
    });

    return {
      title,
      description,
      addNotes,
      notes,
    };
  },
};
</script>
