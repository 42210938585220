<template>
  <SchedulerComponent />
</template>

<script>
import SchedulerComponent from "@/components/Scheduler.vue";
export default {
  components: { SchedulerComponent },
};
</script>
