import moment from "moment";
import { formatDate } from "./helpers";
export const schedConfig = () => {
    return {
        createEventOnDblClick: false,
        columns: [
          {
            text: "Name",
            field: "name",
            width: 225,
            renderer: ({ record, cellElement }) => {
              // TODO: To debug - https://forum.bryntum.com/viewtopic.php?t=20553
              // createApp(ResourceRenderer, { record }).mount(cellElement)
  
              cellElement.classList.add("tech-row");
  
              // Basic implementation: https://www.bryntum.com/products/scheduler/docs/api/Scheduler/column/ResourceInfoColumn#configs
              // const imageUrl = `https://ui-avatars.com/api/?name=${record.name}`;
              return {
                class: "yourOwnWrapperClass",
                children: [
                  {
                    tag: "i",
                    class: "b-fa-user b-fa me-2 tech-icon",
                  },
                  {
                    tag: "span",
                    class: "",
                    html: record.name,
                  },
                ],
              };
            },
          },
        ],
        startDate: new Date(2023, 1, 1),
        endDate: new Date(2023, 1, 10),
        resources: [],
        events: [],
        listeners: {},
        features: {
          eventEdit: false,
          scheduleTooltip: {
              bodyCls: {
              "custom-shadow": 1,
              },
              generateTipContent({ date, resourceRecord }) {
              const dateFormat = "dddd MMM DD, yyyy";
              return `
                      <div class=''>
                      <dt>Date</dt><dd>${moment(date).format(dateFormat)}</dd>
                      <dt>Technician</dt><dd>${resourceRecord.data.name}</dd>
                      </div>
                  `;
              },
          },
          eventTooltip: {
              nonWorkingTime: true,
              bodyCls: {
                "event-tooltip": 1,
              },
              template: (data) => {
                const record = data.eventRecord.data;
                const dateFormat = "MMM DD, yyyy hh:mm a";
                if (record.type === "tasks") {
                    return `
                    <div class='event-tooltip custom-shadow'>
                        <div class='header px-3 py-2 text-white text-left'>
                            ${record.name}
                        </div>
                        <div class='body px-3 py-2'>
                          <div class="row">
                            <div class="col-md-6">
                              <small class="d-block">Primary Contact</small>
                              <p class="fw-semibold">${record.employeeName ?? "-"}</p>
                            </div>
                            <div class="col-md-6">
                              <small class="d-block">Contact Number</small>
                              <p class="fw-semibold">${record.phone ?? "-"}</p>
                            </div>
                            <div class="col-md-6">
                              <small class="d-block">Start Date</small>
                              <p class="fw-semibold">${formatDate(
                                  record.startDate, dateFormat
                              )}</p>
                            </div>
                            <div class="col-md-6">
                              <small class="d-block">End Date</small>
                              <p class="fw-semibold">${formatDate(
                                  record.endDate, dateFormat
                              )}</p>
                            </div>
                            <div class="col-md-12">
                              <small class="d-block">Description</small>
                              <p class="fw-semibold">${record.description}</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    `;
                } else {
                    return `
                        <div class='event-tooltip custom-shadow'>
                          <div class='header px-3 py-2 text-white text-left'>
                              ${record.name}
                          </div>
                          <div class='body px-3 py-2'>
                            <div class="row">
                              <div class="col-md-6">
                                <small class="d-block">Primary Contact</small>
                                <p class="fw-semibold">${
                                    record.primaryContactName
                                }</p>
                              </div>
                              <div class="col-md-6">
                                <small class="d-block">Mobile Number</small>
                                <p class="fw-semibold">${
                                    record.primaryContactPhone
                                }</p>
                              </div>
                              <div class="col-md-6">
                                <small class="d-block">Start Date</small>
                                <p class="fw-semibold">${formatDate(
                                    record.startDate, dateFormat
                                )}</p>
                              </div>
                              <div class="col-md-6">
                                <small class="d-block">End Date</small>
                                <p class="fw-semibold">${formatDate(
                                    record.endDate, dateFormat
                                )}</p>
                              </div>
                              <div class="col-md-12">
                                <small class="d-block">Description</small>
                                <p class="fw-semibold">${record.jobDescription ? record.jobDescription : "-"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    `;
                }
              },
          },
        },
      }
}

export const defaultEventRenderer = ({ eventRecord, renderData }) => {
  let iconClass = null;
  let eventClass = null;
  if (eventRecord.data.type === "project") {
    iconClass = "b-fa b-fa-briefcase";
    eventClass = "event-project";
    renderData.style = `background-color: ${
      eventRecord.htmlColourStatus
        ? eventRecord.htmlColourStatus
        : eventRecord.backgroundColor
    }`;
  } else {
    iconClass = "b-fa b-fa-tasks";
    eventClass = "event-task";
    renderData.style = "background-color: #dae7e7";
  }

  if (eventRecord.data.type === "project") {
    return `
      <span class="${eventClass} d-flex flex-direction-row align-items-center">
        <i class="${iconClass} event-icon"></i>
        <div>
          <div>
            <span class="b-event-name d-block">${
              eventRecord.data.name
            }</span>
            <small>${
              eventRecord.data.jobDescription
                ? eventRecord.data.jobDescription
                : ""
            }</small>
          </div>
          <div class="d-flex">
            <div class="me-2">${eventRecord.htmlViewStatus}</div>
            <div>${eventRecord.htmlViewTechnicianStatus}</div>
          </div>
        </div>
      </span>
    `;
  } else {
    return `
      <span class="${eventClass} d-flex flex-direction-row align-items-center">
        <i class="${iconClass} event-icon"></i>
        <div>
          <div>
            <span class="b-event-name d-block">${eventRecord.data.name}</span>
            <small>${eventRecord.data.description}</small>
          </div>
        </div>
      </span>
    `;
  }
};