<template>
  <VueDatePicker
    v-bind="$attrs"
    v-model="date"
    :teleport="true"
    :enable-time-picker="enableTimePicker"
    :timezone="timezone"
    @update:model-value="emitUpdate"
  />
  <!-- @update:model-value="emitUpdate" -->
</template>

<script>
import { ref, toRefs } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDate, getTimeZone } from "@/lib/helpers";
export default {
  props: {
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    dateType: {
      type: String,
      default: "datetime",
    },
    timezone: {
      type: String,
      default: () => {
        return getTimeZone();
      },
    },
    value: String,
  },
  model: {
    prop: "value",
    event: "input",
  },
  components: {
    VueDatePicker,
  },
  setup(props, { emit }) {
    const date = ref(null);
    console.log(`date`, date, props);
    const format = ref("YYYY-MM-DDTHH:mm:ss");
    // const { format: $attrsFormat } = attrs;
    const { dateType } = toRefs(props);
    const emitUpdate = (d) => {
      if (dateType.value === "time") {
        format.value = "HH:mm:ss";
      } else {
        format.value = "YYYY-MM-DDTHH:mm:ss";
      }

      const updatedDate = formatDate(d, format.value);
      console.log(`updatedDate`, updatedDate);
      emit("change", updatedDate);
    };

    return {
      date,
      emitUpdate,
    };
  },
};
</script>
