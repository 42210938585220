import "bootstrap/dist/css/bootstrap.min.css"
import 'vue-select/dist/vue-select.css';

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue'
import routes from '@/routes'
import vSelect from 'vue-select';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
const app = createApp(App)
const pinia = createPinia()

app.use(pinia);
app.use(router);
app.mount('#app')

app.component('v-select', vSelect);

import "bootstrap/dist/js/bootstrap.js"
