<template>
  <div class="row">
    <div class="col-md-12 mb-3 d-flex align-center align-items-center">
      <div class="w-100 me-3">
        <span class="field-label">Material Name</span>
        <input class="form-control" type="text" v-model="name" />
      </div>
      <div class="mt-3 pt-1">
        <bryntum-button
          icon="b-fa-file"
          text="Add Note"
          color="b-purple"
          cls="b-raised align-items-center"
          @click="addMaterial"
        />
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <span class="field-label">Quantity</span>
      <input class="form-control" type="text" v-model="quantity" />
    </div>
    <div class="col-md-6 mb-3">
      <span class="field-label">Price</span>
      <input class="form-control" type="text" v-model="price" />
    </div>
    <div class="col-md-12 mb-3">
      <span class="field-label">Note</span>
      <textarea
        v-model="description"
        class="form-control"
        cols="30"
        rows="4"
      ></textarea>
    </div>

    <div class="col-md-12">
      <div class="card" v-for="item in materials" :key="item.id">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { GlobalService } from "@/services/GlobalService";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
import { v4 as uuidv4 } from "uuid";
import MODULE from "@/lib/module";
export default {
  props: {
    projectId: [String, Number],
  },
  components: {
    BryntumButton,
  },
  setup(props) {
    const globalService = new GlobalService();
    const route = useRoute();
    const timer = ref(null);
    const materials = ref([]);
    const name = ref("");
    const quantity = ref(1);
    const price = ref(0);
    const notes = ref("");
    const description = ref("");
    const params = reactive({
      t: route.query.t,
      u: route.query.u,
      uid: 1,
      userID: 1,
      s: "Jupiter",
      id: props.projectId,
      parentActionId: MODULE.PROJECTS.ACTION_ID,
      actionId: MODULE.PROJECTS.UX.SERVICE_MATERIAL,
    });

    const loadServiceMaterials = async () => {
      const res = await globalService.getTableData({
        ...params,
        correlationId: uuidv4(),
      });

      materials.value = res.data.resources;
    };

    const setupInterval = () => {
      if (timer.value) return;
      timer.value = setInterval(loadServiceMaterials, 5000);
    };

    const addMaterial = async () => {
      // if (!description.value) return;
      // await globalService.addProjectNote({
      //   ...params,
      //   notes: title.value,
      //   summary: description.value,
      //   id: 0,
      // });
      // title.value = "";
      // description.value = "";
    };

    onMounted(() => {
      loadServiceMaterials();
      setupInterval();
    });

    onUnmounted(() => {
      clearInterval(timer.value);
      timer.value = null;
    });

    return {
      name,
      quantity,
      price,
      notes,
      addMaterial,
      materials,
      description,
    };
  },
};
</script>
