import { defineStore } from 'pinia';
import { ref, reactive } from 'vue'

export const useFiltersStore = defineStore('filters', () => {
    // const startDate = new Date();
    // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    const filters = ref({})
    const address = ref(null);
    const dueDate = ref(null);
    const dateRange = ref([]);
    const selectedProjectType = ref([]);
    const selectedStatus = ref([]);
    const selectedClient = ref([]);
    const selectedTechnician = ref([]);
    const selectedTechCrewStatus = ref([]);
    const exclude = reactive({
      projectType: [],
      projectStatus: [],
      techStatus: [],
    });

    function saveFilters(payload) {
        filters.value = payload;
    }

    return {
        filters,
        saveFilters,
        address,
        dueDate,
        dateRange,
        selectedProjectType,
        selectedStatus,
        selectedClient,
        selectedTechnician,
        selectedTechCrewStatus,
        exclude
    }
})