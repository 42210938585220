import axios from 'axios'
import { ApiService } from './ApiService'
import queryString from 'query-string'
import { generateGUID } from '@/lib/helpers'

export class GlobalService extends ApiService {
    constructor() {
        super();
    }

    async getTableData(query) {
        try {
            const q = queryString.stringify(query);
            return await axios.get(`/global/list/tab?${q}`, this.apiHeaderObj)
        } catch (err) {
            console.error("[ERROR] get all task schedules - ", err)
        }
    }

    async getAllTechnicians(t) {
        return await axios.get(`/scheduler/technicians?t=${t}`, this.apiHeaderObj);
    }

    async saveActivity(data) {
        try {
            return await axios.post('/global/activity', data, this.apiHeaderObj)
        } catch (err) {
            console.error(`[ERROR] failed saving new activity`, err)
        }
    }

    async createNewWorkOrder(data) {
        try {
            return await axios.post('/projects/new', data, this.apiHeaderObj)
        } catch (err) {
            console.error(`[ERROR] failed saving new activity`, err)
        }
    }

    async getProjectDetails(query) {
        try {
            // https://saturn-west-test.azurewebsites.net/api/projects/details?t=b604d9ec-2ccf-4dd2-92c5-9824fac11428&u=f17c2640-933b-4e01-aa0b-2fbe518f71f9&s=Jupiter&uid=1&userID=1&test=123&id=54277
            const q = queryString.stringify(query);
            const res = await axios.get(`/projects/details?${q}`, this.apiHeaderObj)
            return res.data.resources
        } catch (err) {
            console.error(`[ERROR] failed getting project details`, err)
        }
    }

    async updateActivity(data) {
        try {
            return await axios.put('/global/update', data, this.apiHeaderObj)
        } catch (err) {
            console.error(`[ERROR] failed updating activity`, err)
        }
    }
    
    async dispatchProject(data) {
        try {
            return await axios.post('/dispatch/projects', data, this.apiHeaderObj)
        } catch (err) {
            console.error(`[ERROR] failed updating activity`, err)
        }
    }
  
    async reassignProject(data) {
        try {
            return await axios.put('/scheduler/projects/put', data, this.apiHeaderObj)
        } catch (err) {
            console.error(`[ERROR] failed updating activity`, err)
        }
    }

    async getProjectByQuery(query) {
        try {
            const q = queryString.stringify(query);
            return await axios.get(`/search/dashboard?${q}`, this.apiHeaderObj)
        } catch (e) {
            console.error("[ERROR] get project details by query - ", e);
        }
    }

    async getClientDetails(query) {
        try {
            const q = queryString.stringify(query);
            const res = await axios.get(`/client/details?${q}`, this.apiHeaderObj)
            return res.data.resources
        } catch (e) {
            console.error("[ERROR] get client details by query - ", e);
        }
    }

    async getAllProjectSchedules(query) {
        try {
            const q = queryString.stringify(query);
            return await axios.get(`/scheduler/projects?${q}`, this.apiHeaderObj)
        } catch (err) {
            console.error("[ERROR] get all project schedules - ", err)
        }
    }

    async getAllEmployees(query) {
        try {
            const q = queryString.stringify(query);
            const res = await axios.get(`/employees/list/get?${q}`, this.apiHeaderObj)
            return res.data.resources
        } catch (err) {
            console.error("[ERROR] get all employees - ", err)
        }
    }

    async getProjectNotes(query) {
        try {
            const q = queryString.stringify(query);
            const res = await axios.get(`/notes/projects?${q}`, this.apiHeaderObj)
            return res.data.resources
        } catch (err) {
            console.error("[ERROR] get all employees - ", err)
        }
    }

    async addProjectNote(data) {
        try {
            return await axios.post('/notes/add', data, this.apiHeaderObj)
        } catch (err) {
            console.error(`[ERROR] failed saving new notes`, err)
        }
    }

    async getUXDropdown(query) {
        try {
            query.correlationId = generateGUID();
            const q = queryString.stringify(query);
            const res = await axios.get(`/ux/dropdown?${q}`, this.apiHeaderObj)
            return res.data.resources
        } catch (err) {
            console.error("[ERROR] get ux dropdown - ", err)
        }
    }

    async getEditDetails(query) {
        try {
            const q = queryString.stringify(query);
            const res = await axios.get(`/global/edit?${q}`, this.apiHeaderObj)
            return res.data.resources
        } catch (err) {
            console.error(`[ERROR] failed getting edit details`, err)
        }
    }
}
