<template>
  <Transition name="modal">
    <div v-if="visible" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header px-3 py-2">
            <h5 class="text-white mb-0">Create Task</h5>
            <bryntum-button
              icon="b-fa-times"
              color="b-white"
              cls="b-white modal-close-btn"
              @click="$emit('close')"
            />
          </div>
          <div class="modal-body scrollbar scrollable-div">
            <div
              class="card border-0 pt-3 pb-1 px-3 mb-3 rounded-0 custom-shadow"
            >
              <div class="row filters">
                <div class="col-md-12 mb-3">
                  <span class="field-label">Task Name</span>
                  <input class="form-control" type="text" v-model="eventName" />
                </div>
                <div class="col-md-6 mb-3">
                  <span class="field-label">Start Date</span>
                  <DatePicker
                    class="datepicker mt-1"
                    style="width: 100% !important"
                    v-model="startDate"
                    :enable-time-picker="true"
                    format="dd-MMM-yyyy hh:mm a"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <span class="field-label">End Date</span>
                  <DatePicker
                    class="datepicker mt-1"
                    style="width: 100% !important"
                    v-model="endDate"
                    :enable-time-picker="true"
                    format="dd-MMM-yyyy hh:mm a"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start p-3">
            <bryntum-button
              icon="b-fa-search"
              text="Save"
              color="b-purple"
              cls="b-raised"
              :disabled="isSaving"
              @click="saveEvent"
            />
            <bryntum-button
              icon="b-fa-times"
              text="Cancel"
              color="b-red"
              cls="b-raised"
              :disabled="isSaving"
              @click="$emit('close')"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { ref, watch } from "vue";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
import DatePicker from "@/components/widgets/DatePicker";
import { formatDate } from "@/lib/helpers";

export default {
  emits: ["close", "save"],
  props: {
    visible: Boolean,
    eventRecord: Object,
    resource: Object,
  },
  components: {
    BryntumButton,
    DatePicker,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, context) {
    const eventName = ref(null);
    const startDate = ref(null);
    const endDate = ref(null);
    const resourceId = ref(null);
    const isSaving = ref(false);

    watch(
      () => props.visible,
      (value) => {
        if (value) {
          eventName.value = props.eventRecord.eventName;
          startDate.value = props.eventRecord.startDate;
          endDate.value = props.eventRecord.endDate;
          resourceId.value = props.eventRecord.resourceId;

          isSaving.value = false;
        }
      }
    );

    const saveEvent = () => {
      isSaving.value = true;
      context.emit("save", {
        name: eventName.value,
        startDate: formatDate(startDate.value, "DD-MMM-yyyy hh:mm a"),
        endDate: formatDate(endDate.value, "DD-MMM-yyyy hh:mm a"),
        resource: props.resource,
      });
    };
    return {
      eventName,
      startDate,
      endDate,
      resourceId,
      saveEvent,
      isSaving,
    };
  },
};
</script>
