<template>
  <Transition name="modal">
    <div v-if="visible" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header px-3 py-2">
            <h5 class="text-white mb-0">Edit Task</h5>
            <bryntum-button
              icon="b-fa-times"
              color="b-white"
              cls="b-white modal-close-btn"
              @click="$emit('close')"
            />
          </div>
          <div class="modal-body scrollbar scrollable-div">
            <div
              class="card border-0 pt-3 pb-1 px-3 mb-3 rounded-0 custom-shadow"
            >
              <div class="row filters">
                <div class="col-md-12 mb-3">
                  <span class="field-label">Task Name</span>
                  <input
                    class="form-control"
                    type="text"
                    v-model="eventName"
                    @input="
                      () =>
                        updateField(
                          'TaskName',
                          eventName,
                          'Task Name',
                          eventName
                        )
                    "
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <span class="field-label">Start Date</span>
                  <DatePicker
                    class="datepicker mt-1"
                    style="width: 100% !important"
                    v-model="startDate"
                    :enable-time-picker="true"
                    format="dd-MMM-yyyy hh:mm a"
                    @change="
                      (d) => updateDateField('startDate', d, 'Start Date', d)
                    "
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <span class="field-label">End Date</span>
                  <DatePicker
                    class="datepicker mt-1"
                    style="width: 100% !important"
                    v-model="endDate"
                    :enable-time-picker="true"
                    format="dd-MMM-yyyy hh:mm a"
                    @change="
                      (d) => updateDateField('endDate', d, 'End Date', d)
                    "
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <span class="field-label">Description</span>
                  <textarea
                    class="form-control"
                    type="text"
                    v-model="description"
                    @input="
                      () =>
                        updateField(
                          'Description',
                          description,
                          'description',
                          description
                        )
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start p-3">
            <bryntum-button
              icon="b-fa-times"
              text="Close"
              color="b-red"
              cls="b-raised"
              @click="$emit('close')"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, toRefs, watch } from "vue";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
import { debounce } from "lodash/function";
import { formatDate, formatUTCdate } from "@/lib/helpers";
import { GlobalService } from "@/services/GlobalService";
import { commonData, hashUpdateRequiredFields } from "@/lib/extractor";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "@/components/widgets/DatePicker";
import MODULE from "@/lib/module";
export default {
  emits: ["close", "save"],
  props: {
    visible: Boolean,
    taskId: {
      type: [String, Number],
      default: () => null,
    },
  },
  components: {
    BryntumButton,
    DatePicker,
  },
  setup(props) {
    const globalService = new GlobalService();
    const eventName = ref("");
    const description = ref("");
    const startDate = ref(null);
    const endDate = ref(null);
    const route = useRoute();
    const common = commonData(route.query, false);

    const { taskId, visible } = toRefs(props);
    const initializeTask = async () => {
      const task = await globalService.getEditDetails({
        ...common,
        id: taskId.value,
        actionId: MODULE.TASK,
        correlationId: uuidv4(),
        moduleId: MODULE.PROJECTS.MODULE_ID,
      });

      eventName.value = task.TaskName;
      description.value = task.Description;
      startDate.value = formatDate(task.StartDate);
      endDate.value = formatDate(task.EndDate);
    };

    const updateDateField = (
      propertyName,
      propertyValue,
      displayName,
      displayValue
    ) => {
      const dValue = formatUTCdate(displayValue, "MMM DD, yyyy hh:mm a");
      const pValue = formatUTCdate(propertyValue);
      updateField(propertyName, pValue, displayName, dValue);
    };

    const updateField = debounce(
      async (propertyName, propertyValue, displayName, displayValue) => {
        const commonWithTimeZone = commonData(route.query);
        const rawPayload = {
          ...commonWithTimeZone,
          actionId: MODULE.TASK,
          id: props.taskId,
          propertyName,
          propertyValue,
          displayName,
          displayValue,
          moduleId: MODULE.PROJECTS.MODULE_ID,
          ignore: false,
        };

        const payload = hashUpdateRequiredFields(rawPayload);
        await globalService.updateActivity(payload);
      },
      500
    );

    watch(visible, (value) => {
      if (value) {
        initializeTask();
      }
    });

    return {
      eventName,
      description,
      startDate,
      endDate,
      updateDateField,
      updateField,
    };
  },
};
</script>
