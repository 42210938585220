<template>
  <div class="row filters">
    <div class="col-md-4 mb-3">
      <span class="field-label">Status / Priority</span>
      <v-select
        v-model="selectedStatus"
        :options="statusList"
        code="id"
        label="displayName"
        :clearable="false"
        @option:selected="
          (d) =>
            updateField(
              'IntProjectStatusID',
              d.id,
              'Status / Priority',
              d.displayName
            )
        "
      />
    </div>
    <div class="col-md-4 mb-3">
      <span class="field-label">Job / Project Type</span>
      <v-select
        v-model="selectedProjectType"
        :options="projectTypeList"
        code="id"
        label="displayName"
        :clearable="false"
        @option:selected="
          (d) =>
            updateField(
              'IntProjectTypeID',
              d.id,
              'Job/Project Type',
              d.displayName
            )
        "
      />
    </div>
    <div class="col-md-4 mb-3">
      <span class="field-label">Tech / Crew Status</span>
      <v-select
        v-model="selectedTechCrewStatus"
        :options="techCrewStatus"
        code="id"
        label="displayName"
        :clearable="false"
        @option:selected="
          (d) =>
            updateField(
              'IntSMSMessageStatusId',
              d.id,
              'Tech/Crew Status',
              d.displayName
            )
        "
      />
    </div>

    <div class="col-md-4 mb-3">
      <span class="field-label">Primary Contact Name</span>
      <input
        class="form-control"
        type="text"
        v-model="contactName"
        @input="
          () =>
            updateField(
              'primaryContactName',
              contactName,
              'Primary Contact Name',
              contactName
            )
        "
      />
    </div>
    <div class="col-md-4 mb-3">
      <span class="field-label">Primary Contact Phone</span>
      <input
        class="form-control"
        type="text"
        v-model="contactPhone"
        @input="
          () =>
            updateField(
              'primaryContactPhone',
              contactPhone,
              'Primary Contact Phone',
              contactPhone
            )
        "
      />
    </div>

    <div class="col-md-4 mb-3">
      <span class="field-label">Est. Completion Date</span>
      <DatePicker
        class="datepicker mt-1"
        style="width: 100% !important"
        v-model="endDate"
        :enable-time-picker="true"
        format="dd-MMM-yyyy hh:mm a"
        @change="
          (d) => updateDateField('projectEndDate', d, 'Est. Completion Date', d)
        "
      />
    </div>
    <div class="col-md-4 mb-3">
      <span class="field-label">Start Date</span>
      <DatePicker
        class="datepicker mt-1"
        style="width: 100% !important"
        v-model="startDate"
        :enable-time-picker="true"
        format="dd-MMM-yyyy hh:mm a"
        @change="(d) => updateDateField('projectStartDate', d, 'Start Date', d)"
      />
    </div>
    <div class="col-md-4 mb-3">
      <span class="field-label">Due Date</span>
      <DatePicker
        class="datepicker mt-1"
        style="width: 100% !important"
        v-model="dueDate"
        :enable-time-picker="false"
        format="dd-MMM-yyyy"
        @change="(d) => updateDateField('dueDate', d, 'Due Date', d)"
      />
    </div>
    <div class="col-md-4 mb-3">
      <span class="field-label">Appointment Date & Time</span>
      <DatePicker
        class="datepicker mt-1"
        style="width: 100% !important"
        v-model="appointmentDate"
        :enable-time-picker="true"
        format="dd-MMM-yyyy hh:mm a"
        @change="
          (d) =>
            updateDateField('confirmationDate', d, 'Appointment Date & Time', d)
        "
      />
    </div>
    <div class="col-md-12 mb-3">
      <span class="field-label">Job Description</span>
      <textarea
        v-model="eventName"
        class="form-control"
        cols="30"
        rows="8"
        @input="
          () =>
            updateField(
              'jobDescription',
              eventName,
              'Job Description',
              eventName
            )
        "
      ></textarea>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import { ref, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { GlobalService } from "@/services/GlobalService";
import { commonData, hashUpdateRequiredFields } from "@/lib/extractor";
import { debounce } from "lodash/function";
import { formatDate, formatUTCdate } from "@/lib/helpers";
import DatePicker from "@/components/widgets/DatePicker";
import MODULE from "@/lib/module";
export default {
  props: {
    projectId: [String, Number],
    project: {
      type: Object,
      required: true,
    },
  },
  components: {
    DatePicker,
  },
  setup(props) {
    const globalService = new GlobalService();
    const project = ref(null);
    const eventName = ref(null);
    const startDate = ref(null);
    const endDate = ref(null);
    const dueDate = ref(null);
    const appointmentDate = ref(null);
    const resourceId = ref(null);
    const contactName = ref(null);
    const contactPhone = ref(null);
    const selectedProjectType = ref(null);
    const selectedStatus = ref(null);
    const projectTypeList = ref([]);
    const selectedTechCrewStatus = ref(null);
    const techCrewStatus = ref([]);
    const statusList = ref([]);
    const route = useRoute();

    onMounted(async () => {
      await loadDropdowns();
      loadProject();
    });

    onUnmounted(() => {
      resetForm();
    });

    const updateDateField = (
      propertyName,
      propertyValue,
      displayName,
      displayValue
    ) => {
      const dValue = formatUTCdate(displayValue, "MMM DD, yyyy hh:mm a");
      const pValue = formatUTCdate(propertyValue);
      updateField(propertyName, pValue, displayName, dValue);
    };

    const updateField = debounce(
      async (propertyName, propertyValue, displayName, displayValue) => {
        const common = commonData(route.query);
        const rawPayload = {
          ...common,
          actionId: MODULE.PROJECTS.ACTION_ID,
          id: props.projectId,
          referenceNumber: props.project.projectRefNumber,
          propertyName,
          propertyValue,
          displayName,
          displayValue,
          moduleId: MODULE.PROJECTS.MODULE_ID,
          ignore: false,
        };

        const payload = hashUpdateRequiredFields(rawPayload);
        await globalService.updateActivity(payload);
      },
      500
    );

    const loadProject = () => {
      selectedStatus.value = statusList.value.find(
        (d) => d.id === props.project.intProjectStatusID
      );
      selectedProjectType.value = projectTypeList.value.find(
        (d) => d.id === props.project.intProjectTypeID
      );
      selectedTechCrewStatus.value = techCrewStatus.value.find(
        (d) => d.id === props.project.intSmsmessageStatusId
      );
      contactName.value = props.project.primaryContactName;
      contactPhone.value = props.project.primaryContactPhone;
      startDate.value = formatDate(props.project.projectStartDate);
      endDate.value = formatDate(props.project.projectEndDate);
      dueDate.value = formatDate(props.project.dueDate);
      appointmentDate.value = formatDate(props.project.confirmationDate);
      eventName.value = props.project.jobDescription;
    };

    const loadDropdowns = async () => {
      const params = {
        t: route.query.t,
        u: route.query.u,
        uid: 1,
        userid: 1,
        s: "Jupiter",
      };

      techCrewStatus.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.TECHNICIAN_STATUS,
      });

      projectTypeList.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.PROJECTS.UX.TYPE,
      });

      statusList.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.PROJECTS.UX.STATUS,
      });
    };

    const resetForm = () => {
      project.value = null;
      selectedStatus.value = null;
      selectedProjectType.value = null;
      selectedTechCrewStatus.value = null;
      contactName.value = null;
      contactPhone.value = null;
      startDate.value = null;
      endDate.value = null;
      eventName.value = null;
    };

    const viewWorkOrder = () => {
      const url = `https://portal.snapsuite.io/projects/details/${props.projectId}`;
      window.open(url, "_");
    };

    return {
      eventName,
      startDate,
      endDate,
      dueDate,
      appointmentDate,
      resourceId,
      selectedProjectType,
      projectTypeList,
      selectedStatus,
      statusList,
      contactName,
      contactPhone,
      techCrewStatus,
      selectedTechCrewStatus,
      viewWorkOrder,
      updateField,
      updateDateField,
    };
  },
};
</script>
