<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header px-3 py-2">
            <h5 class="text-white mb-0">Advance Search</h5>
            <bryntum-button
              icon="b-fa-times"
              color="b-white"
              cls="b-white modal-close-btn"
              @click="$emit('close')"
            />
          </div>
          <div class="modal-body scrollbar scrollable-div">
            <div
              class="card border-0 pt-3 pb-1 px-3 mb-3 rounded-0 custom-shadow"
            >
              <h6 class="mb-3">Filter by Client</h6>
              <div class="row filters">
                <div class="col-md-6 mb-3">
                  <span class="field-label">Client</span>
                  <v-select
                    multiple
                    v-model="selectedClient"
                    :options="clients"
                    code="id"
                    label="displayName"
                  />
                </div>

                <div class="col-md-6 mb-3">
                  <span class="field-label">Address</span>
                  <input class="form-control" type="text" v-model="address" />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Job/Project Type</span>
                  <v-select
                    multiple
                    v-model="selectedProjectType"
                    :options="projectTypeList"
                    code="id"
                    label="displayName"
                  />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Job #</span>
                  <input class="form-control" type="text" />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Assigned To</span>
                  <v-select
                    multiple
                    v-model="selectedTechnician"
                    :options="techniciansList"
                    code="id"
                    label="name"
                  />
                </div>
              </div>
            </div>

            <div
              class="card border-0 pt-3 pb-1 px-3 mb-3 rounded-0 custom-shadow"
            >
              <h6 class="mb-3">Status</h6>
              <div class="row">
                <div class="col-md-4 mb-3">
                  <span class="field-label">Status/Priority</span>
                  <v-select
                    multiple
                    v-model="selectedStatus"
                    :options="projectStatusList"
                    code="id"
                    label="displayName"
                  />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Tech/Crew Status</span>
                  <v-select
                    multiple
                    v-model="selectedTechCrewStatus"
                    :options="techCrewStatusList"
                    code="id"
                    label="displayName"
                  />
                </div>
              </div>
            </div>

            <div
              class="card border-0 pt-3 pb-1 px-3 mb-3 rounded-0 custom-shadow"
            >
              <h6 class="mb-3">Date</h6>
              <div class="row filters">
                <div class="col-md-4 mb-3">
                  <span class="field-label">Filter By Date Range</span>
                  <!-- @update:model-value="onSelectDateRange" -->
                  <VueDatePicker
                    class="datepicker datepicker-advance-search mt-1"
                    style="width: 100% !important"
                    :model-value="dateRange"
                    :teleport="true"
                    range
                    multi-calendars
                    multi-calendars-solo
                    :enable-time-picker="false"
                  />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Due Date</span>
                  <VueDatePicker
                    class="datepicker datepicker-advance-search mt-1"
                    style="width: 100% !important"
                    v-model="dueDate"
                    :teleport="true"
                    :enable-time-picker="false"
                  />
                </div>
              </div>
            </div>

            <div
              class="card bg-gray border-0 pt-3 pb-1 px-3 rounded-0 custom-shadow"
            >
              <h6 class="mb-3">Exlude</h6>
              <div class="row filters">
                <div class="col-md-4 mb-3">
                  <span class="field-label">Job/Project Type</span>
                  <v-select
                    multiple
                    class="bg-white"
                    v-model="exclude.projectType"
                    :options="projectTypeList"
                    code="id"
                    label="displayName"
                  />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Status/Priority</span>
                  <v-select
                    multiple
                    class="bg-white"
                    v-model="exclude.projectStatus"
                    :options="projectStatusList"
                    code="id"
                    label="displayName"
                  />
                </div>

                <div class="col-md-4 mb-3">
                  <span class="field-label">Tech/Crew Status</span>
                  <v-select
                    multiple
                    class="bg-white"
                    v-model="exclude.techStatus"
                    :options="techCrewStatusList"
                    code="id"
                    label="displayName"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-start p-3">
            <bryntum-button
              icon="b-fa-search"
              text="Search"
              color="b-purple"
              cls="b-raised"
              @click="onSaveFilter"
            />
            <bryntum-button
              icon="b-fa-times"
              text="Cancel"
              color="b-red"
              cls="b-raised"
              @click="$emit('close')"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
import { GlobalService } from "@/services/GlobalService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useFiltersStore } from "@/stores/filters";
import moment from "moment";
import MODULE from "@/lib/module";
export default {
  emits: ["close", "search"],
  props: {
    show: Boolean,
  },

  components: {
    BryntumButton,
    VueDatePicker,
  },
  setup(_, context) {
    const globalService = new GlobalService();
    const route = useRoute();
    const filtersStore = useFiltersStore();
    const format = "yyyy-MM-DD";
    const address = computed({
      get: () => filtersStore.address,
      set: (newValue) => {
        filtersStore.address = newValue;
      },
    });

    const dateRange = computed({
      get: () => filtersStore.dateRange,
      set: (newValue) => {
        filtersStore.dateRange = newValue ? newValue : null;
      },
    });

    const dueDate = computed({
      get: () => filtersStore.dueDate,
      set: (newValue) => {
        filtersStore.dueDate = newValue
          ? moment(newValue).format(format)
          : null;
      },
    });

    const selectedProjectType = computed({
      get: () => filtersStore.selectedProjectType,
      set: (newValue) => {
        filtersStore.selectedProjectType = newValue;
      },
    });

    const selectedStatus = computed({
      get: () => filtersStore.selectedStatus,
      set: (newValue) => {
        filtersStore.selectedStatus = newValue;
      },
    });

    const selectedClient = computed({
      get: () => filtersStore.selectedClient,
      set: (newValue) => {
        filtersStore.selectedClient = newValue;
      },
    });

    const selectedTechnician = computed({
      get: () => filtersStore.selectedTechnician,
      set: (newValue) => {
        filtersStore.selectedTechnician = newValue;
      },
    });

    const selectedTechCrewStatus = computed({
      get: () => filtersStore.selectedTechCrewStatus,
      set: (newValue) => {
        filtersStore.selectedTechCrewStatus = newValue;
      },
    });

    const exclude = computed({
      get: () => filtersStore.exclude,
      set: (newValue) => {
        filtersStore.exclude = newValue;
      },
    });

    const projectTypeList = ref([]);
    const projectStatusList = ref([]);
    const techniciansList = ref([]);
    const techCrewStatusList = ref([]);
    const clients = ref([]);

    const onSaveFilter = () => {
      let filters = {};
      if (address.value) filters.address = address.value;
      if (dueDate.value) filters.dueDate = dueDate.value;
      if (dateRange.value) {
        filters.startDate = moment(dateRange.value[0]).format(format);
        filters.endDate = moment(dateRange.value[1]).format(format);
      }

      if (selectedProjectType.value && selectedProjectType.value.length > 0)
        filters.projectTypeIds = selectedProjectType.value
          .map((d) => d.id)
          .join(",");

      if (selectedStatus.value && selectedStatus.value.length > 0)
        filters.projectStatusIds = selectedStatus.value
          .map((d) => d.id)
          .join(",");

      if (selectedClient.value && selectedClient.value.length > 0)
        filters.clientIds = selectedClient.value.map((d) => d.id).join(",");

      if (selectedTechnician.value && selectedTechnician.value.length > 0)
        filters.technicianIds = selectedTechnician.value
          .map((d) => d.id)
          .join(",");

      if (
        selectedTechCrewStatus.value &&
        selectedTechCrewStatus.value.length > 0
      )
        filters.technicianStatusIds = selectedTechCrewStatus.value
          .map((d) => d.id)
          .join(",");

      // if (exclude.value.projectType && exclude.value.projectType.length > 0)
      //   filters.exclude.projectType = exclude.value.projectType
      //     .map((d) => d.id)
      //     .join(",");

      // if (exclude.value.projectStatus && exclude.value.projectStatus.length > 0)
      //   filters.exclude.projectStatus = exclude.value.projectStatus
      //     .map((d) => d.id)
      //     .join(",");

      // if (exclude.value.techStatus && exclude.value.techStatus.length > 0)
      //   filters.exclude.techStatus = exclude.value.techStatus
      //     .map((d) => d.id)
      //     .join(",");

      filtersStore.saveFilters(filters);
      context.emit("search");
      context.emit("close");
    };

    const initializeDropdowns = async () => {
      const params = {
        t: route.query.t,
        u: route.query.u,
        uid: 1,
        userid: 1,
        s: "Jupiter",
      };

      projectTypeList.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.PROJECTS.UX.TYPE,
      });

      projectStatusList.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.PROJECTS.UX.STATUS,
      });

      const res = await globalService.getAllTechnicians(route.query.t);
      techniciansList.value =
        res.data && res.data.resources ? res.data.resources : [];

      clients.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.CLIENT.ACTION_ID,
      });

      techCrewStatusList.value = await globalService.getUXDropdown({
        ...params,
        actionId: MODULE.TECHNICIAN_STATUS,
      });
    };

    onMounted(async () => {
      initializeDropdowns();
    });

    return {
      address,
      selectedProjectType,
      selectedStatus,
      selectedClient,
      selectedTechnician,
      selectedTechCrewStatus,
      projectTypeList,
      projectStatusList,
      techniciansList,
      clients,
      techCrewStatusList,
      dueDate,
      dateRange,
      exclude,
      onSaveFilter,
    };
  },
};
</script>
