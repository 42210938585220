import SchedulerView from '@/views/SchedulerView.vue';

const routes = [
    {
      path: '/',
      component: SchedulerView,
      name: 'home',
    },
];

export default routes;