import { GlobalService } from "@/services/GlobalService";
import { extractPayload, hashPayload } from "@/lib/extractor";
import { formatDate } from "@/lib/helpers";
const globalService = new GlobalService();
const format = "DD-MMM-yyyy hh:mm a";

export const eventDropTask = async (context, query) => {
  const eventRecord = context.eventRecord;
  const oldResource = context.resourceRecord;
  const newResource = context.newResource;
  const oldStartDate = formatDate(context.origStart, "YYYY-MM-DDTHH:mm:ss");
  const oldEndDate = formatDate(context.origEnd, "YYYY-MM-DDTHH:mm:ss");
  const newStartDate = formatDate(context.startDate, "YYYY-MM-DDTHH:mm:ss");
  const newEndDate = formatDate(context.endDate, "YYYY-MM-DDTHH:mm:ss");

  const newData = {
    ...eventRecord,
    resource: newResource,
    startDate: newStartDate,
    endDate: newEndDate,
    dueDate: newEndDate,
  };

  if (oldResource.id !== newResource.id) {
    // update resource
    const payload = extractPayload(
      newData,
      query,
      "IntEmployeeID"
    );
    await globalService.updateActivity(payload);
  }

  if (oldStartDate !== newStartDate) {
    // update start date
    const startDatePayload = extractPayload(
      newData,
      query,
      "startDate"
    );
    await globalService.updateActivity(startDatePayload);
  }

  if (oldEndDate !== newEndDate) {
    // update end date
    const endDatePayload = extractPayload(
      newData,
      query,
      "endDate"
    );
    await globalService.updateActivity(endDatePayload);
  }
}

export const eventResizeEndTask = async (eventRecord, resourceRecord, query) => {
  const newStartDate = formatDate(eventRecord.startDate, format);
  const newEndDate = formatDate(eventRecord.endDate, format);

  const newData = {
      ...eventRecord,
      resource: resourceRecord,
      startDate: newStartDate,
      endDate: newEndDate,
      dueDate: newEndDate,
  };

  const startDatePayload = extractPayload(
      newData,
      query,
      "startDate"
  );
  await globalService.updateActivity(startDatePayload);

  const endDatePayload = extractPayload(
      newData,
      query,
      "endDate"
  );
  await globalService.updateActivity(endDatePayload);
}

export const createTask = async (values, query) => {
  console.log(`values`, values);
  const newData = {
    ...values,
    startDate: values.startDate,
    endDate: values.endDate,
    dueDate: values.endDate,
    data: {
      id: 0,
      type: "tasks",
    },
  };

  const payload = hashPayload(newData, query);
  await globalService.saveActivity(payload);
  // console.log(`payload`, payload)
}