import moment from 'moment-timezone'
export const generateGUID = function() {
  let guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

  guid = guid.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  return guid;
};

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const formatDateFromUTC = (date, format = "YYYY-MM-DD hh:mm a", timeZone = getTimeZone()) => {
  return moment.tz(formatUTCdate(date), timeZone).format(format);
};

export const formatDate = (date, format = "YYYY-MM-DD hh:mm a", timeZone = getTimeZone()) => {
  return moment.tz(date, timeZone).format(format);
};

export const formatUTCdate = (date, format = "YYYY-MM-DDTHH:mm:ss") => {
  return moment.tz(date, "UTC").format(format);
}

export const formatStartEndDate = (date, type = 'start', timeZone = getTimeZone()) => {
  const myDate = moment(date, timeZone)
  return type === 'start' ? myDate.format("YYYY-MM-DDT09:00:00") : myDate.format("YYYY-MM-DDT17:00:00")
}