<template>
  <div class="demo-toolbar p-0">
    <loading :active="isLoading" :is-full-page="true" />
    <div :class="[{ 'py-2 ms-2': !project }, 'left-section mx-0']">
      <LeftSectionToolBar @view="handleViewPreset" @search="handleSearch" />
    </div>

    <div v-if="schedulerConfig">
      <RightSectionToolBar
        @range-update="updateDateRange"
        @view="handleViewPreset"
      />
    </div>
  </div>
</template>

<script>
import LeftSectionToolBar from "./LeftSectionToolBar.vue";
import RightSectionToolBar from "./RightSectionToolBar.vue";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { inject } from "vue";
export default {
  emits: ["view"],
  props: {
    schedulerConfig: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LeftSectionToolBar,
    RightSectionToolBar,
    Loading,
  },
  setup(props, { emit }) {
    const project = inject("project");
    const handleViewPreset = (day) => {
      emit("view", day);
    };

    const handleSearch = () => {
      emit("search");
    };

    const updateDateRange = (dateRange) => {
      emit("range-update", dateRange);
    };

    return {
      project,
      handleViewPreset,
      handleSearch,
      updateDateRange,
    };
  },
};
</script>
