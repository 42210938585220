import axios from 'axios';

const API_URL = 'https://saturn-west-test.azurewebsites.net/api/';
const apiKey = 'EjD0natpoof1cHJ32SzvhB6DSHh4JeHC94oV44/Mm3X2337WKUOAjg==';

export class ApiService {
    constructor() {
        axios.defaults.baseURL = API_URL;

        this.apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }
    }
}