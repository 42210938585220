<template>
  <Transition name="modal">
    <div v-if="visible" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header px-3 py-2">
            <h5 v-if="project" class="text-white mb-0">
              Edit Job: {{ project.projectRefNumber }} -
              {{ project.projectName }}
            </h5>
            <bryntum-button
              icon="b-fa-times"
              color="b-white"
              cls="b-white modal-close-btn"
              @click="$emit('close')"
            />
          </div>
          <div class="modal-body scrollbar scrollable-div">
            <div
              class="card border-0 pt-3 pb-1 px-3 mb-3 rounded-0 custom-shadow"
            >
              <Loading :active="isLoading" :is-full-page="true" />
              <!-- tabs -->
              <ul class="nav nav-tabs modal-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#details-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="details-tab-pane"
                    aria-selected="true"
                    href="#"
                  >
                    Details
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="notes-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#notes-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="notes-tab-pane"
                    aria-selected="false"
                  >
                    Notes
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="service-materials-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#service-materials-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="service-materials-tab-pane"
                    aria-selected="false"
                  >
                    Service Materials
                  </button>
                </li>
              </ul>
              <div class="tab-content pt-3" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="details-tab-pane"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                  tabindex="0"
                >
                  <DetailsTab
                    v-if="project"
                    :project="project"
                    :project-id="project.intProjectID"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  id="notes-tab-pane"
                  role="tabpanel"
                  aria-labelledby="notes-tab"
                  tabindex="0"
                >
                  <NotesTab v-if="project" :project-id="project.intProjectID" />
                </div>
                <div
                  class="tab-pane fade"
                  id="service-materials-tab-pane"
                  role="tabpanel"
                  aria-labelledby="service-materials-tab"
                  tabindex="0"
                >
                  <ServiceMaterialsTab
                    v-if="project"
                    :project-id="project.intProjectID"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start p-3">
            <!-- <bryntum-button
              icon="b-fa-search"
              text="Save"
              color="b-purple"
              cls="b-raised"
              @click="saveEvent"
            /> -->
            <bryntum-button
              icon="b-fa-times"
              text="Close"
              color="b-red"
              cls="b-raised"
              @click="$emit('close')"
            />

            <bryntum-button
              icon="b-fa-eye"
              text="View Job"
              color="b-blue"
              cls="b-raised"
              style="margin-left: auto"
              @click="viewJob"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// import moment from "moment";
import { ref, watch, toRefs } from "vue";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
import { useRoute } from "vue-router";
import { GlobalService } from "@/services/GlobalService";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import DetailsTab from "./tabs/DetailsTab.vue";
import NotesTab from "./tabs/NotesTab.vue";
import ServiceMaterialsTab from "./tabs/ServiceMaterialsTab.vue";
export default {
  emits: ["close", "save"],
  props: {
    visible: Boolean,
    projectId: [String, Number],
  },
  components: {
    BryntumButton,
    Loading,
    DetailsTab,
    NotesTab,
    ServiceMaterialsTab,
  },
  setup(props) {
    const globalService = new GlobalService();
    const isLoading = ref(false);
    const project = ref(null);
    const route = useRoute();
    const { projectId, visible } = toRefs(props);
    watch(visible, (value) => {
      if (!value) {
        resetForm();
      }
    });

    watch(projectId, (value) => {
      if (value) {
        loadProject();
      }
    });

    const loadProject = async () => {
      isLoading.value = true;
      const params = {
        t: route.query.t,
        u: route.query.u,
        uid: 1,
        userid: 1,
        s: "Jupiter",
        id: props.projectId,
      };
      project.value = await globalService.getProjectDetails(params);
      isLoading.value = false;
    };

    const resetForm = () => {
      project.value = null;
    };

    const viewJob = () => {
      const url = `https://portal.snapsuite.io/projects/details/${props.projectId}`;
      window.open(url, "_");
    };

    return {
      project,
      isLoading,
      viewJob,
    };
  },
};
</script>
