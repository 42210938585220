<template>
  <div v-if="project" class="p-2 ms-0 wo-card bg-purple me-3">
    <h6 class="text-white me-2 mb-0">Job ({{ $route.query.projRef }})</h6>
    <small class="text-white"
      ><strong>{{ project.businessName }}</strong> -
      {{ project.location }}</small
    >
  </div>
  <bryntum-button-group
    :items="buttonGroupItems"
    cls="b-raised align-items-center me-3"
    :toggleGroup="true"
    :callOnFunctions="true"
    color="b-purple"
  />
  <bryntum-text-field
    placeholder="Search Jobs"
    :inputWidth="300"
    cls="align-items-center me-3"
  />
  <bryntum-button
    icon="b-fa-search"
    text="Advance Search"
    color="b-purple"
    cls="b-raised align-items-center me-3"
    @click="searchModal = true"
  />

  <Teleport to="body">
    <AdvanceSearchModal
      :show="searchModal"
      @search="search"
      @close="searchModal = false"
    />
  </Teleport>
</template>

<script>
import AdvanceSearchModal from "@/components/filter/AdvanceSearchModal.vue";
import {
  BryntumButton,
  BryntumButtonGroup,
  BryntumTextField,
} from "@bryntum/scheduler-vue-3";
import { inject, ref } from "vue";
export default {
  emits: ["view", "search"],
  components: {
    AdvanceSearchModal,
    BryntumButton,
    BryntumButtonGroup,
    BryntumTextField,
  },
  setup(props, { emit }) {
    const project = inject("project");
    const searchModal = ref(false);
    const buttonGroupItems = [
      { text: "Today", onClick: () => emit("view", "today") },
      // {
      //   text: "Last Week",
      //   onClick: () => {
      //     emit("view", "lastweek");
      //   },
      // },
      {
        text: "Week",
        pressed: true,
        onClick: () => {
          emit("view", "week");
        },
      },
      // {
      //   text: "Next Week",
      //   onClick: () => {
      //     emit("view", "nextweek");
      //   },
      // },
      {
        text: "Month",
        onClick: () => {
          emit("view", "month");
        },
      },
      {
        text: "Quarter",
        onClick: () => {
          emit("view", "quarter");
        },
      },
      {
        text: "Year",
        onClick: () => {
          emit("view", "year");
        },
      },
    ];

    const search = () => {
      emit("search");
    };

    return {
      searchModal,
      project,
      buttonGroupItems,
      search,
    };
  },
};
</script>
