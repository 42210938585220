<template>
  <div style="height: 100vh">
    <ToolBar
      :scheduler-config="schedulerConfig"
      :is-loading="isLoading"
      :currentDateRangeValue="dateRange"
      @view="setViewPreset"
      @search="initializeSchedules"
      @range-update="onSelectDateRange"
    />
    <div class="content-container">
      <div class="scheduler-container">
        <bryntum-scheduler
          ref="scheduler"
          v-bind="schedulerConfig"
          :event-renderer="defaultEventRenderer"
          :rowHeight="85"
        />
      </div>
      <div v-if="false" class="grid-container">
        <bryntum-grid
          ref="grid"
          v-bind="gridConfig"
          :data="gridData"
          :features="{
            stripe: true,
            cellEdit: false,
            sort: 'title',
          }"
          :rowHeight="85"
        />
      </div>
    </div>

    <Teleport to="body">
      <WorkOrderForm
        :visible="workOrderFormVisible"
        :event-record="unsaveEvent"
        :resource="unsaveResource"
        :saving="isFormSaving"
        @save="handleWorkOrderSaveEvent"
        @close="closeWorkOrderForm"
      />
      <EditWorkOrder
        :visible="editWorkOrderFormVisible"
        :project-id="editWorkOrderID"
        @close="closeEditWorkOrderForm"
      />
      <TaskForm
        :visible="taskFormVisible"
        :event-record="unsaveEvent"
        :resource="unsaveResource"
        @save="handleTaskSaveEvent"
        @close="closeTaskForm"
      />
      <EditTaskForm
        :visible="editTaskVisible"
        :task-id="editTaskId"
        @close="closeEditTaskForm"
      />
    </Teleport>
  </div>
</template>

<script>
import { reactive, onMounted, ref, onUnmounted, computed, provide } from "vue";
import { useRoute } from "vue-router";
import { GlobalService } from "@/services/GlobalService";
import { schedConfig, defaultEventRenderer } from "@/lib/schedulerConfig";
import { eventDropTask, eventResizeEndTask, createTask } from "@/lib/task";
import {
  eventDropWorkOrder,
  eventResizeEndWorkOrder,
  createWorkOrder,
} from "@/lib/workorder";
import { formatDate } from "@/lib/helpers";
import { getJobsList, getTaskList } from "@/lib/extractor";
import moment from "moment";
import { BryntumScheduler } from "@bryntum/scheduler-vue-3";
import { BryntumGrid } from "@bryntum/grid-vue-3";
import WorkOrderForm from "./modals/WorkOrderForm.vue";
import EditWorkOrder from "./modals/EditWorkOrder.vue";
import TaskForm from "./modals/TaskForm.vue";
import EditTaskForm from "./modals/EditTaskForm.vue";
import ToolBar from "@/components/header/ToolBarComponent.vue";
import { useFiltersStore } from "@/stores/filters";
import MODULE from "@/lib/module";

export default {
  name: "AppScheduler",

  components: {
    BryntumScheduler,
    BryntumGrid,
    WorkOrderForm,
    TaskForm,
    EditWorkOrder,
    ToolBar,
    EditTaskForm,
  },

  setup() {
    const globalService = new GlobalService();
    const scheduler = ref(null); // reference to scheduler component
    const schedulerInstance = ref(null);
    const route = useRoute();
    const filtersStore = useFiltersStore();
    const timer = ref(null);
    const idleTimeout = ref(null);
    const project = ref(null);
    const isLoading = ref(false);
    const workOrderFormVisible = ref(false);
    const isFormSaving = ref(false);
    const editWorkOrderID = ref(null);
    const editWorkOrderFormVisible = ref(false);
    const taskFormVisible = ref(false);
    const editTaskVisible = ref(false);
    const editTaskId = ref(null);
    const unsaveEvent = ref(null);
    const unsaveResource = ref(null);
    const advanceSearchModal = reactive({ show: false });
    const dateRange = computed({
      get: () => filtersStore.dateRange,
      set: (newValue) => {
        filtersStore.dateRange = newValue ? newValue : null;
      },
    });

    const schedulerConfig = reactive(schedConfig());

    const gridConfig = reactive({
      columns: [{ field: "title", text: "Unassigned Tasks", flex: 1 }],
    });

    const gridData = ref(null);

    const viewPreset = ref({
      base: "hourAndDay",
      tickWidth: 20,
      columnLinesFor: 0,
      headers: [
        {
          unit: "d",
          align: "center",
          dateFormat: "LLLL",
        },
        {
          unit: "h",
          align: "center",
          dateFormat: "hh:mm",
        },
      ],
    });

    const setViewPreset = async (preset, useDateRangeValues = false) => {
      let startDate = null;
      let endDate = null;
      let minZoomLevel = 17;

      const defaultPreset = {
        base: "weekAndMonth",
        tickWidth: 20,
        columnLinesFor: 0,
        headers: [
          {
            unit: "w",
            align: "center",
            dateFormat: "ddd D MMM",
          },
          {
            unit: "d",
            align: "center",
            dateFormat: "D ddd",
          },
        ],
      };

      switch (preset) {
        case "week": {
          startDate = moment().startOf("week").set("hour", 6).toString();
          endDate = moment().endOf("week").set("hour", 18).toString();
          minZoomLevel = 11;

          viewPreset.value = {
            base: "weekAndDay",
            headers: [
              {
                unit: "w",
                align: "center",
                dateFormat: "MMMM, YYYY",
              },
              {
                unit: "d",
                align: "center",
                dateFormat: "D ddd",
              },
            ],
          };

          break;
        }

        case "nextweek": {
          startDate = moment()
            .startOf("week")
            .add(1, "week")
            .set("hour", 6)
            .toString();
          endDate = moment()
            .endOf("week")
            .add(1, "week")
            .set("hour", 18)
            .toString();
          minZoomLevel = 11;

          break;
        }

        case "lastweek": {
          startDate = moment()
            .startOf("week")
            .subtract(1, "week")
            .set("hour", 6)
            .toString();
          endDate = moment()
            .endOf("week")
            .subtract(1, "week")
            .set("hour", 18)
            .toString();
          minZoomLevel = 11;

          break;
        }

        case "month": {
          startDate = moment().startOf("month").toString();
          endDate = moment().endOf("month").toString();
          minZoomLevel = 8;

          viewPreset.value = {
            ...defaultPreset,
            base: "weekAndMonth",
            headers: [
              {
                unit: "w",
                align: "center",
                dateFormat: "D MMM",
              },
              {
                unit: "d",
                align: "center",
                dateFormat: "D",
              },
            ],
          };

          break;
        }

        case "quarter": {
          startDate = moment().startOf("quarter").toString();
          endDate = moment().endOf("quarter").toString();
          minZoomLevel = 5;

          viewPreset.value = {
            ...defaultPreset,
            base: "weekAndMonth",
            headers: [
              {
                unit: "w",
                align: "center",
                dateFormat: "MMM",
              },
              {
                unit: "d",
                align: "center",
                dateFormat: "D",
              },
            ],
          };

          break;
        }

        case "year": {
          startDate = moment().startOf("year").toString();
          endDate = moment().endOf("year").toString();
          minZoomLevel = 3;

          viewPreset.value = {
            ...defaultPreset,
            base: "weekAndMonth",
            headers: [
              {
                unit: "w",
                align: "center",
                dateFormat: "MMM YYYY",
              },
              {
                unit: "d",
                align: "center",
                dateFormat: "D",
              },
            ],
          };

          break;
        }

        case "today": {
          startDate = moment().set("hour", 6).toString();
          endDate = moment().set("hour", 18).toString();
          minZoomLevel = 15;
          viewPreset.value = {
            timeResolution: {
              unit: "hour",
              increment: 1,
            },

            headers: [
              {
                unit: "day",
                dateFormat: "DD MMM YYYY",
              },
              {
                unit: "hour",
                dateFormat: "hA",
              },
            ],
          };
          break;
        }

        default: {
          startDate = moment().set("hour", 6).toString();
          endDate = moment().set("hour", 18).toString();

          viewPreset.value = {
            base: "hourAndDay",
            headers: [
              {
                unit: "d",
                align: "center",
                dateFormat: "Do ddd MMM",
              },
              {
                unit: "hour",
                align: "center",
                dateFormat: "h:mm a",
              },
            ],
          };
          break;
        }
      }

      if (useDateRangeValues) {
        startDate = dateRange.value[0];
        endDate = dateRange.value[1];
      }

      refreshCalendar({
        minZoomLevel,
        startDate,
        endDate,
      });
    };

    const refreshCalendar = (config) => {
      // minZoomLevel
      const { startDate, endDate, minZoomLevel } = config;

      dateRange.value = [startDate, endDate];

      schedulerConfig.startDate = startDate;
      schedulerConfig.endDate = endDate;

      scheduler.value.instance.value.minZoomLevel = minZoomLevel; // Sets minZoomLevel to hourly
      scheduler.value.instance.value.zoomLevel = minZoomLevel;
      scheduler.value.instance.value.maxZoomLevel = 21; // Sets maxZoomOut level to 15-minutes view

      scheduler.value.instance.value.startDate = startDate;
      scheduler.value.instance.value.endDate = endDate;
    };

    const onSelectDateRange = (value) => {
      if (!value || !value[0] || !value[1]) {
        setViewPreset("today");
        return;
      }
      dateRange.value = value;

      const startdate = moment(value[0]);
      const endDate = moment(value[1]);

      const dateDiffInDays = moment(endDate).diff(startdate, "days");

      console.log("[INFO] selected dates - ", value[0], value[1]);
      console.log("[INFO] difference in days - ", dateDiffInDays);

      if (dateDiffInDays < 7) {
        setViewPreset("today", true);
      } else if (dateDiffInDays >= 7 && dateDiffInDays <= 29) {
        setViewPreset("week", true);
      } else {
        setViewPreset("year", true);
      }
    };

    const initializeProject = async () => {
      const params = {
        t: route.query.t,
        u: route.query.u,
        id: 0,
        page: 1,
        maximumRows: 10,
        projectRefNumber: route.query.id,
        uid: 1,
        userid: 1,
        s: "Jupiter",
      };

      const response = await globalService.getProjectByQuery(params);
      if (response && response.data && response.data.resources)
        project.value = response.data.resources.find(
          (p) => parseInt(p.intProjectID) === parseInt(params.projectRefNumber)
        );
    };

    const initializeSchedules = async () => {
      let params = {
        t: route.query.t,
        u: route.query.u,
      };

      if (filtersStore.filters) {
        params = {
          ...params,
          ...filtersStore.filters,
        };
      }
      let schedules = [];
      if (route.query.id) {
        initializeProject();
        params.id = route.query.id;
        params.actionId = MODULE.TASK;
        params.parentActionId = MODULE.PROJECTS.ACTION_ID;
        params.moduleId = MODULE.PROJECTS.MODULE_ID;

        const items = await getTaskList(params);
        schedules = [...schedules, ...items];
      } else {
        params.actionId = MODULE.PROJECTS.UX.SCHEDULES;
        params.moduleId = MODULE.PROJECTS.MODULE_ID;
        params.startDate = formatDate(schedulerConfig.startDate, "yyyy-MM-DD");
        params.endDate = formatDate(schedulerConfig.endDate, "yyyy-MM-DD");
        // get events
        const jobs = await getJobsList(params);
        schedules = [...schedules, ...jobs];

        // get task no project id required
        let taskPayload = {
          t: route.query.t,
          u: route.query.u,
          actionId: MODULE.TASK,
          parentActionId: MODULE.PROJECTS.ACTION_ID,
          moduleId: MODULE.PROJECTS.MODULE_ID,
        };
        if (filtersStore.filters) {
          taskPayload = {
            ...taskPayload,
            ...filtersStore.filters,
          };
        }
        const items = await getTaskList(taskPayload);
        schedules = [...schedules, ...items];
      }

      schedulerConfig.events = schedules;
      startScheduleInterval();
    };

    const initializeUnassignedTasks = () => {
      gridData.value = [
        {
          title: "W04085 Pipe installation + Footing Reinforcement",
          duration: 10,
        },
        { title: "W04975 Compacting ground", duration: 8 },
        { title: "W04041 Footing reinforcement / Concreete", duration: 5 },
        { title: "W02381 Repair wiring systems", duration: 5 },
        { title: "W07035 Fixing electrical conduits", duration: 6 },
      ];
    };

    const initializeTechnicians = async () => {
      const response = await globalService.getAllTechnicians(route.query.t);
      if (!response || !response.data || !response.data.resources) return;
      schedulerConfig.resources = response.data.resources;
    };

    const startScheduleInterval = () => {
      if (timer.value) return;
      timer.value = setInterval(initializeSchedules, 5000);
    };

    const handleUserActivity = () => {
      clearInterval(timer.value);
      timer.value = null;
      clearTimeout(idleTimeout.value);

      // Resume the interval after 15 seconds of inactivity
      idleTimeout.value = setTimeout(startScheduleInterval, 5000);
    };

    onMounted(async () => {
      isLoading.value = true;
      await initializeUnassignedTasks();
      await initializeTechnicians();
      schedulerConfig.startDate = moment().set("hour", 6).toString();
      schedulerConfig.endDate = moment().set("hour", 18).toString();

      schedulerInstance.value = scheduler.value.instance.value;

      schedulerConfig.listeners = {
        // beforeEventEditShow: (context) => {
        //   console.log(`before event edit show`, context);
        //   return false;
        // },
        eventDrop: async ({ context }) => {
          if (route.query && route.query.id) {
            eventDropTask(context, route.query);
          } else {
            if (
              context.eventRecord &&
              context.eventRecord.data &&
              context.eventRecord.data.type === "tasks"
            ) {
              eventDropTask(context, route.query);
            } else {
              eventDropWorkOrder(context, route.query);
            }
          }
          setTimeout(initializeSchedules, 4000);
        },
        eventResizeEnd: async ({ eventRecord, resourceRecord }) => {
          if (route.query && route.query.id) {
            eventResizeEndTask(eventRecord, resourceRecord, route.query);
          } else {
            if (eventRecord.data && eventRecord.data.task === "tasks") {
              eventResizeEndTask(eventRecord, resourceRecord, route.query);
            } else {
              eventResizeEndWorkOrder(eventRecord, resourceRecord, route.query);
            }
          }
          setTimeout(initializeSchedules, 4000);
        },
        beforeEventSave: async ({ values }) => {
          if (route.query && route.query.id) {
            await createTask(values, route.query);
          } else {
            await createWorkOrder(values, route.query);
          }
          setTimeout(initializeSchedules, 4000);
        },

        // eventClick: ({ eventRecord, resourceRecord }) => {
        //   console.log("Event clicked!", eventRecord, resourceRecord);
        // },
        // scheduleClick: ({ eventRecord, resourceRecord }) => {
        // },
        // scheduleDblClick: ({ eventRecord, resourceRecord }) => {
        //   if (route.query && !route.query.id) {
        //     showWorkOrderForm(resourceRecord, eventRecord);
        //   }
        // },
        eventDblClick: ({ resourceRecord, eventRecord }) => {
          if (eventRecord.type === "project") {
            showEditWorkOrderForm(resourceRecord, eventRecord);
          } else {
            showEditTaskModal(resourceRecord, eventRecord);
          }
        },
        dragCreateEnd: ({ resourceRecord, eventRecord }) => {
          if (route.query && !route.query.id) {
            showWorkOrderForm(resourceRecord, eventRecord);
          } else {
            showTaskForm(resourceRecord, eventRecord);
          }
        },
      };

      await initializeSchedules();
      startScheduleInterval();
      isLoading.value = false;

      // Listen for user activity
      // window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      window.addEventListener("mousedown", handleUserActivity);
    });

    onUnmounted(() => {
      clearInterval(timer.value);
      clearTimeout(idleTimeout.value);

      timer.value = null;
      idleTimeout.value = null;
      // Remove event listeners
      // window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("mousedown", handleUserActivity);
    });

    const showWorkOrderForm = (resourceRecord, eventRecord) => {
      unsaveEvent.value = eventRecord.data;
      unsaveResource.value = resourceRecord.data;
      workOrderFormVisible.value = true;
    };

    const showEditWorkOrderForm = (resourceRecord, eventRecord) => {
      editWorkOrderID.value = eventRecord.id;
      editWorkOrderFormVisible.value = true;
    };

    const showEditTaskModal = (resourceRecord, eventRecord) => {
      editTaskId.value = eventRecord.id;
      editTaskVisible.value = true;
    };

    const showTaskForm = (resourceRecord, eventRecord) => {
      unsaveEvent.value = eventRecord.data;
      unsaveResource.value = resourceRecord.data;
      taskFormVisible.value = true;
    };

    const clearUnsaveData = () => {
      unsaveEvent.value = null;
      unsaveResource.value = null;
    };

    const handleWorkOrderSaveEvent = async (data) => {
      isFormSaving.value = true;
      await createWorkOrder(data, route.query);
      clearUnsaveData();
      setTimeout(initializeSchedules, 5000);
      isFormSaving.value = false;
      workOrderFormVisible.value = false;
    };

    const handleTaskSaveEvent = async (data) => {
      await createTask(data, route.query);
      closeTaskForm();
      setTimeout(initializeSchedules, 4000);
    };

    const closeWorkOrderForm = () => {
      workOrderFormVisible.value = false;
      if (unsaveEvent.value) {
        const eventStore = schedulerInstance.value.eventStore;
        const eventRecord = eventStore.getById(unsaveEvent.value.id);
        eventStore.remove(eventRecord);
        schedulerInstance.value.refresh();
        clearUnsaveData();
      }
    };

    const closeEditWorkOrderForm = async () => {
      editWorkOrderFormVisible.value = false;
      editWorkOrderID.value = null;
      await initializeSchedules();
    };

    const closeTaskForm = () => {
      taskFormVisible.value = false;
      if (unsaveEvent.value) {
        const eventStore = schedulerInstance.value.eventStore;
        const eventRecord = eventStore.getById(unsaveEvent.value.id);
        eventStore.remove(eventRecord);
        schedulerInstance.value.refresh();
        clearUnsaveData();
      }
    };

    const closeEditTaskForm = async () => {
      editTaskVisible.value = false;
      await initializeSchedules();
    };

    provide("project", project);
    provide("dateRange", dateRange);

    return {
      scheduler,
      schedulerConfig,
      defaultEventRenderer,
      initializeSchedules,
      project,
      isLoading,
      dateRange,
      onSelectDateRange,
      gridConfig,
      gridData,
      workOrderFormVisible,
      handleWorkOrderSaveEvent,
      advanceSearchModal,
      unsaveEvent,
      unsaveResource,
      closeWorkOrderForm,
      taskFormVisible,
      handleTaskSaveEvent,
      closeTaskForm,
      editWorkOrderFormVisible,
      editWorkOrderID,
      closeEditWorkOrderForm,
      isFormSaving,
      setViewPreset,
      editTaskVisible,
      editTaskId,
      closeEditTaskForm,
    };
  },
};
</script>
<style lang="scss">
@import "@/App.scss";
</style>
