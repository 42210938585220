import hash from 'object-hash'
import { v4 as uuidv4 } from 'uuid'
import { formatDate, getTimeZone, formatUTCdate } from './helpers'
import { GlobalService } from "@/services/GlobalService";
import MODULE from './module'
const globalService = new GlobalService();
export const commonData = (eventRecord, includeTimezone = true) => {
    let common = {
        // SnapSuite specific
        t: eventRecord.t,
        u: eventRecord.u,
        uid: eventRecord.uid,
        userid: eventRecord.userID,
        s: "Jupiter",
    }

    if (includeTimezone) common.timezone = getTimeZone()
    // if (includeTimezone) common.timezone = "America/New_York"
    return common 
}
export const dataTaskExtractor = (eventRecord) => {
    if (!eventRecord || eventRecord === null) return null
    const common = commonData(eventRecord);

    return {
        endDate: formatUTCdate(eventRecord.endDate),
        startDate: formatUTCdate(eventRecord.startDate),
        dueDate: formatUTCdate(eventRecord.endDate),

        employeeId: eventRecord.resource.employeeId,
        IntEmployeeID: eventRecord.resource.employeeId,
        employeeName: eventRecord.employee.employeeName,

        intTaskTypeID: 1,
        referenceNumber: eventRecord.projRef,
        taskName: `${eventRecord.name}`,
        Description: 'Created with scheduler',
        actionId: MODULE.TASK,
        parentActionId: parseInt(eventRecord.actionId),
        parentId: parseInt(eventRecord.id) !== parseInt(eventRecord.data.id) ? parseInt(eventRecord.id) : parseInt(eventRecord.data.intProjectID),
        moduleId: 20,
        taskId: parseInt(eventRecord.data.id),
        type: eventRecord.data.type,
        ...common
    }
}

export const dataWorkOrderExtractor = (eventRecord) => {
    if (!eventRecord || eventRecord === null) return null
    const common = commonData(eventRecord);
    return {
        projectEndDate: formatUTCdate(eventRecord.endDate),
        projectStartDate: formatUTCdate(eventRecord.startDate),
        employeeId: eventRecord.resource.employeeId,
        employeeGuid: eventRecord.resource.resourceId,
        jobDescription: eventRecord.name,

        // static
        actionId: MODULE.PROJECTS.ACTION_ID,
        clientId: eventRecord.client,
        locationId: eventRecord.location?.id,
        projectStatusId: eventRecord.projectStatus?.id,
        projectTypeId: eventRecord.projectType?.id,
        primaryContactPhone: eventRecord.contactPhone,
        primaryContactName: eventRecord.contactName,
        moduleId: MODULE.PROJECTS.MODULE_ID,

        ...common
    }
}

export const dispatchWorkOrderExtractor = (eventRecord) => {
    if (!eventRecord || eventRecord === null) return null

    const common = commonData(eventRecord);
    return {
        fromEmployeeID: eventRecord.oldResource.employeeId,
        StartDate: formatUTCdate(eventRecord.startDate),
        EndDate: formatUTCdate(eventRecord.endDate),
        schedulerStartDate: formatUTCdate(eventRecord.startDate),
        EmployeeID: eventRecord.resource.employeeId,
        EmployeeUniqueId: eventRecord.resource.employeeId,
        ProjectID: parseInt(eventRecord.data.id),
        projectUniqueId: eventRecord.data.guid,
        message: `${eventRecord.data.projectRefnumber} - ${eventRecord.data.clientName} - ${eventRecord.data.primaryContactName} - ${eventRecord.data.primaryContactPhone} \n ${eventRecord.data.locationAddress} \n ${eventRecord.data.jobDescription}`,
        
        ...common
    }
}

export const resourceExtractor = (eventRecord, propertyName = 'resource') => {
    if (!eventRecord || eventRecord === null) return null
    let resource = null
    if (eventRecord && eventRecord.$linkedResources && eventRecord.$linkedResources.length > 0) {
        resource = eventRecord.$linkedResources[0].data
    } else {
        resource = eventRecord[propertyName]
    }

    return {
        resourceId: resource.resourceId,
        employee: {
            employeeId: resource.id,
            id: resource.id,
            resourceId: resource.resourceId,
            employeeName: resource.name,
            role: resource.role
        }
    }
}

export const hashPayload = (event, query, type = 'task') => {
    const item = resourceExtractor(event)
    let data = {}
    if (type === 'task') {
        data = dataTaskExtractor({
            ...event,
            ...item,
            ...query
        })
    } else {
        data = dataWorkOrderExtractor({
            ...event,
            ...item,
            ...query
        })
    }
    
    const myHash = hash({
        ...data
    })

    const payload = {
        ...data,
        hash: myHash
    }

    return payload;
}

export const dispatchPayload = (event, query) => {
    const item = resourceExtractor(event);
    const oldResource = resourceExtractor(event, 'oldResource');
    return dispatchWorkOrderExtractor({
        ...event,
        ...oldResource,
        ...item,
        ...query
    })
}

const globalExtractor = (type, payload) => {
    let data = {}
    if (type === 'task') {
        data = dataTaskExtractor(payload)
    } else {
        data = dataWorkOrderExtractor(payload)
    }

    return data
}

// const projectCompleteMapData = (eventRecord) => {
//     const format = 'DD-MMM-yyyy hh:mm a'
//     const common = commonData(eventRecord);
//     return {
//         projectEndDate: formatDate(eventRecord.endDate, format),
//         projectStartDate: formatDate(eventRecord.startDate, format),
//         employeeId: eventRecord.resource.employeeId,
//         employeeGuid: eventRecord.resource.resourceId,
//         jobDescription: eventRecord.name,

//         // static
//         actionId: MODULE.PROJECTS.ACTION_ID,
//         moduleId: MODULE.PROJECTS.MODULE_ID,
//         clientId: eventRecord.client,
//         locationId: eventRecord.location.id,
//         IntProjectStatusID: eventRecord.IntProjectStatusID,
//         projectTypeId: eventRecord.projectType.id,
//         primaryContactPhone: eventRecord.contactPhone,
//         primaryContactName: eventRecord.contactName,

//         ...common
//     }
// }

export const hashUpdateRequiredFields = (rawPayload) => {
    const myHash = hash({
        ...rawPayload
    })

    const payload = {
        ...rawPayload,
        hash: myHash,
        correlationId: uuidv4()
    }
    return payload;
}

export const extractPayload = (event, query, propertyName, type = 'task') => {
    const item = resourceExtractor(event);
    const data = globalExtractor(type, {
        ...event,
        ...item,
        ...query
    })

    const rawPayload = {
        actionId: data.actionId,
        id: data.actionId === MODULE.TASK ? parseInt(data.taskId) : parseInt(event.data.id),
        referenceNumber: "",
        propertyName,
        propertyValue: data[propertyName],
        displayName: propertyName,
        moduleId: data.moduleId,
        ignore: false,
        t: data.t,
        u: data.u,
        uid: data.uid,
        userid: data.userid,
        s: data.s,
        timezone: data.timezone,
    }

    const payload = hashUpdateRequiredFields({
        ...rawPayload
    })

    return payload;
}

export const getTaskList = async (payload) => {
    const response = await globalService.getTableData(payload);
    if (!response || !response.data || !response.data.resources) return [];
    const schedules = []
    response.data.resources.map((data) => {
        schedules.push({
            id: parseInt(data.id),
            name: `${data.name} - TASK`,
            startDate: formatDate(data.startDate),
            endDate: formatDate(data.endDate),
            resourceId: parseInt(data.employeeId),
            employeeName: data.employeeName,
            phone: data.phone,
            type: "tasks",
            description: data.description,
        });
    });

    return schedules;
}

export const getJobsList = async (payload) => {
    const response = await globalService.getAllProjectSchedules(payload);
    const schedules = [];
    if (!response || !response.data || !response.data.resources) return;
    response.data.resources.map((data) => {
        schedules.push({
            ...data,
            id: data.projectId,
            guid: data.id,
            clientName: data.name,
            name: generateName(data),
            startDate: formatDate(data.startDate),
            endDate: formatDate(data.endDate),
            type: "project",
            resourceId: data.employeeId,
        });
    });

    return schedules;
}


const generateName = (data) => {
    let name = data.projectRefnumber;

    if (data.name) name += ` - ${data.name}`
    if (data.locationAddress) name += ` - ${data.locationAddress}`
    if (data.type) name += ` - ${data.type}`

    return name
}
