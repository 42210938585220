<template>
  <div class="right-section">
    <bryntum-button
      class="me-2"
      icon="b-fa-chevron-left"
      color="b-purple"
      @click="updateDateRange('lastweek')"
    />
    <VueDatePicker
      class="datepicker"
      :model-value="dateRange"
      range
      multi-calendars
      multi-calendars-solo
      :enable-time-picker="false"
      @update:model-value="onSelectDateRange"
    />
    <bryntum-button
      class="mx-2"
      icon="b-fa-chevron-right"
      color="b-purple"
      @click="updateDateRange('nextweek')"
    />
    <bryntum-button
      class="me-2"
      text="Print"
      icon="b-fa-print"
      color="b-purple"
    />
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { BryntumButton } from "@bryntum/scheduler-vue-3";
import { ref, onMounted, watch, inject } from "vue";
import moment from "moment";
export default {
  emits: ["view"],
  components: {
    BryntumButton,
    VueDatePicker,
  },
  setup(props, { emit }) {
    const dateRange = ref([]);
    const onSelectDateRange = (value) => {
      dateRange.value = value;
      emit("range-update", value);
    };

    const currentDateRangeValue = inject("dateRange");

    const updateDateRange = (type) => {
      emit("view", type);
    };

    onMounted(() => {
      dateRange.value = [
        moment().startOf("week").set("hour", 6).toString(),
        moment().endOf("week").set("hour", 18).toString(),
      ];
    });

    watch(currentDateRangeValue, (d) => {
      dateRange.value = d;
    });

    return {
      dateRange,
      onSelectDateRange,
      updateDateRange,
    };
  },
};
</script>
