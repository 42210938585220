import moment from "moment";
import { GlobalService } from "@/services/GlobalService";
import { extractPayload, hashPayload, dispatchPayload } from "@/lib/extractor";
import { formatDate } from "./helpers";
const globalService = new GlobalService();
const format = "DD-MMM-yyyy hh:mm a";

export const createWorkOrder = async (values, query) => {
    const newStartDate = moment(values.startDate).format(
        "DD-MMM-yyyy hh:mm a"
      );
      const newEndDate = moment(values.endDate).format(
        "DD-MMM-yyyy hh:mm a"
      );

      const newData = {
        ...values,
        startDate: newStartDate,
        endDate: newEndDate,
      };

      const payload = hashPayload(newData, query, 'project');
      await globalService.createNewWorkOrder(payload);
}

export const eventDropWorkOrder = async (context, query) => {
  const eventRecord = context.eventRecord;
  const oldResource = context.resourceRecord;
  const newResource = context.newResource;
  const oldStartDate = formatDate(context.origStart, format);
  const oldEndDate = formatDate(context.origEnd, format);
  const newStartDate = formatDate(context.startDate, format);
  const newEndDate = formatDate(context.endDate, format);

  const newData = {
    ...eventRecord,
    resource: newResource,
    oldResource: oldResource,
    startDate: newStartDate,
    endDate: newEndDate,
  };

  if (oldResource.id !== newResource.id) {
    // update resource
    const payload = dispatchPayload(
      newData,
      query
    );
    await globalService.reassignProject(payload);
  }

  if (oldStartDate !== newStartDate) {
    // update start date
    const startDatePayload = extractPayload(
      newData,
      query,
      "projectStartDate",
      'project'
    );
    await globalService.updateActivity(startDatePayload);
  }

  if (oldEndDate !== newEndDate) {
    // update end date
    const endDatePayload = extractPayload(
      newData,
      query,
      "projectEndDate",
      'project'
    );
    await globalService.updateActivity(endDatePayload);
  }
}

export const eventResizeEndWorkOrder = async (eventRecord, resourceRecord, query) => {
    const newStartDate = moment(eventRecord.startDate).format(format);
    const newEndDate = moment(eventRecord.endDate).format(format);

    const newData = {
        ...eventRecord,
        resource: resourceRecord,
        startDate: newStartDate,
        endDate: newEndDate,
    };

    const startDatePayload = extractPayload(
        newData,
        query,
        "projectStartDate",
        'project'
    );
    await globalService.updateActivity(startDatePayload);

    const endDatePayload = extractPayload(
        newData,
        query,
        "projectEndDate",
        'project'
    );
    await globalService.updateActivity(endDatePayload);
}